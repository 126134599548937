<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
    >
      <template v-if="Object.keys(statistics).length > 0">
        <transition-group
          mode="out-in"
        >

          <!-- Statistics -->
          <b-row key="statistics">
            <b-col md="12">
              <b-card
                no-body
                class="statistics"
              >
                <b-card-header>
                  <b-card-title>{{$t('Statistics')}}</b-card-title>
                  <b-card-text class="font-small-2 mr-25 mb-0">
                    <!-- Updated 1 month ago -->
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      class="ml-1"
                      @click="updateStatistics"
                    >
                      {{$t('Update')}}
                      <template v-if="isLoading">
                        <b-spinner small />
                      </template>
                    </b-button>
                  </b-card-text>
                </b-card-header>
                <b-card-body class="statistics-body">
                  <b-row>
                    <b-col
                      md="3"
                      cols="6"
                      class="mb-1 mb-md-0"
                    >
                      <b-media no-body>
                        <b-media-aside

                          class="mr-2"
                        >
                          <b-avatar
                            size="48"
                            variant="light-primary"
                          >
                            <feather-icon
                              size="24"
                              icon="TrendingUpIcon"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="font-weight-bolder mb-0">
                            {{statistics.todaySales}} {{$t('SAR')}}
                          </h4>
                          <b-card-text class="font-small-3 mb-0">
                            {{ $t('Today Sales') }}
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <b-col
                      md="3"
                      cols="6"
                      class="mb-1 mb-md-0"
                    >
                      <b-media no-body>
                        <b-media-aside

                          class="mr-2"
                        >
                          <b-avatar
                            size="48"
                            variant="light-primary"
                          >
                            <feather-icon
                              size="24"
                              icon="TrendingUpIcon"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="font-weight-bolder mb-0">
                            {{statistics.todayOrders}}
                          </h4>
                          <b-card-text class="font-small-3 mb-0">
                            {{ $t('Today Sales Count') }}
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>

                    <b-col
                      md="3"
                      cols="6"
                    >
                      <b-media no-body>
                        <b-media-aside

                          class="mr-2"
                        >
                          <b-avatar
                            size="48"
                            variant="light-success"
                          >
                            <feather-icon
                              size="24"
                              icon="TrendingUpIcon"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="font-weight-bolder mb-0">
                            {{statistics.monthSales}} {{$t('SAR')}}
                          </h4>
                          <b-card-text class="font-small-3 mb-0">
                            {{ $t('Month Sales') }}
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <b-col
                      md="3"
                      cols="6"
                    >
                      <b-media no-body>
                        <b-media-aside

                          class="mr-2"
                        >
                          <b-avatar
                            size="48"
                            variant="light-success"
                          >
                            <feather-icon
                              size="24"
                              icon="TrendingUpIcon"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="font-weight-bolder mb-0">
                            {{statistics.monthOrders}}
                          </h4>
                          <b-card-text class="font-small-3 mb-0">
                            {{ $t('Month Sales Count') }}
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <!-- Goal & Orders Last 30 days -->
          <b-row key="goal">
            <!-- Goal -->
            <!-- <b-col
              lg="4"
              md="6"
            >
              <b-card
                v-if="goalOverviewRadialBar"
                no-body
              >
                <b-card-header>
                  <h4 class="mb-0">
                    {{$t('Monthly Goal')}}
                  </h4>
                  <b-card-text class="font-medium-5 mb-0">
                    <feather-icon
                      icon="HelpCircleIcon"
                      size="21"
                      class="text-muted cursor-pointer"
                    />
                  </b-card-text>
                </b-card-header>

                <vue-apex-charts
                  type="radialBar"
                  height="245"
                  class="my-2"
                  :options="goalOverviewRadialBar"
                  :series="goalOverviewRadialBar.data.series"
                />
                <b-row class="text-center mx-0">
                  <b-col
                    cols="6"
                    class="border-top border-right d-flex align-items-between flex-column py-1"
                  >
                    <b-card-text class="text-muted mb-0">
                      {{$t('Completed')}}
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-0">
                      {{ goalOverviewRadialBar.data.completed }}
                    </h3>
                  </b-col>

                  <b-col
                    cols="6"
                    class="border-top d-flex align-items-between flex-column py-1"
                  >
                    <b-card-text class="text-muted mb-0">
                      {{$t('Remaining')}}
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-0">
                      {{ goalOverviewRadialBar.data.inProgress }}
                    </h3>
                  </b-col>
                </b-row>
              </b-card>
            </b-col> -->

            <!-- Orders Last 30 days -->
            <b-col
              lg="12"
              md="12"
            >
              <b-card :title="$t('Orders Last 30 days')">
                <!-- <div class="d-flex justify-content-between flex-wrap">
                  <div class="mb-1 mb-sm-0">
                    <span class="text-muted">Commercial networks and enterprises</span>
                  </div>
                  <div class="d-flex align-content-center mb-1 mb-sm-0">
                    <h1 class="font-weight-bolder ">
                      $ 183,382
                    </h1>
                    <div class="pt-25 ml-75">
                      <b-badge variant="light-secondary">
                        <feather-icon
                          icon="ArrowDownIcon"
                          size="12"
                          class="text-danger align-middle"
                        />
                        <span class="font-weight-bolder align-middle"> 24%</span>
                      </b-badge>
                    </div>
                  </div>
                </div> -->

                <!-- echart -->
                <app-echart-line :option-data="ordersLast30DaysData" :key="appEchartLineKey" />

              </b-card>
            </b-col>
          </b-row>

          <!-- Last Orders & Activities -->
          <b-row key="last-orders">
            <!-- Last Orders -->
            <b-col
              lg="6"
              md="8"
            >
              <b-card
              no-body
              >
                <b-card-header>
                  <b-card-title class="ml-25">
                    {{$t('Last Orders')}}
                  </b-card-title>
                </b-card-header>

                <b-card-body>
                  <template v-for="(lastOrders, i) in statistics.lastOrders">
                    <b-row :key="i">
                      <b-col md="8" cols="8">
                        <b-card-title>
                          <b-link :to="`/orders/`+lastOrders.id">{{lastOrders.username}}</b-link><br>
                          <small>{{lastOrders.mobile}} - #{{lastOrders.id}}</small><br>
                          <img :src="`https://cdn.mhd.sa/asset/images/providers/`+lastOrders.payment_method.name_en+`-logo.png`" height="40"/>
                        </b-card-title>
                      </b-col>
                      <b-col md="4" cols="4">
                        <h3 class="text-success">{{lastOrders.total_price}} {{$t('SAR')}}</h3>
                      </b-col>
                    </b-row>
                  </template>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- Activities -->
            <b-col
              lg="6"
              md="6"
            >
              <b-card
                v-if="timeline"
                no-body
              >
                <b-card-header>
                  <b-card-title class="ml-25">
                    {{$t('Activities')}}
                  </b-card-title>
                </b-card-header>

                <!-- timeline -->
                <b-card-body>
                  <app-timeline>

                    <template v-for="(activit, indexA) in statistics.lastActivities">
                      <div :key="indexA">

                        <!-- Start Checkout -->
                        <app-timeline-item variant="warning" icon="SendIcon" v-if="activit.type == 'StartCheckout'">
                          <div class="d-flex justify-content-between flex-sm-row flex-column mb-2">
                            <h6 v-html="activit.title"></h6>
                            <small class="timeline-item-time text-nowrap text-muted ml-1">{{ activit.duration }}</small>
                          </div>
                          <p></p>
                        </app-timeline-item>

                        <!-- Add To Cart -->
                        <app-timeline-item variant="primary" icon="ShoppingBagIcon" v-if="activit.type == 'AddToCart'">
                          <div class="d-flex justify-content-between flex-sm-row flex-column">
                            <h6 v-html="activit.title"></h6>
                            <small class="timeline-item-time text-nowrap text-muted ml-1">{{ activit.duration }}</small>
                          </div>
                          <b-media no-body class="mb-2">
                            <b-media-aside class="mr-50">
                              <b-avatar
                                :src="activit.avatar"
                                size="38"
                              />
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h6 class="mb-0">
                                {{ activit.avatarName }}
                              </h6>
                            </b-media-body>
                          </b-media>
                        </app-timeline-item>

                        <!-- New Order -->
                        <app-timeline-item variant="success" icon="HeartIcon" v-if="activit.type == 'NewOrder'">
                          <div class="d-flex justify-content-between flex-sm-row flex-column mb-0">
                            <h6>{{ activit.title }}</h6>
                            <small class="timeline-item-time text-nowrap text-muted ml-1">{{ activit.duration }}</small>
                          </div>
                          <p class="mb-2">{{ activit.subtitle }}</p>
                        </app-timeline-item>

                      </div>
                    </template>

                    <!-- <app-timeline-item variant="primary">
                      <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                        <h6>{{ timeline.step1.title }}</h6>
                        <small class="timeline-item-time text-nowrap text-muted ml-1">{{ timeline.step1.duration }}</small>
                      </div>
                      <p>{{ timeline.step1.subtitle }}</p>
                    </app-timeline-item>

                    <app-timeline-item variant="warning">
                      <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                        <h6>{{ timeline.step2.title }}</h6>
                        <small class="timeline-item-time text-nowrap text-muted ml-1">{{ timeline.step2.duration }}</small>
                      </div>
                      <p>{{ timeline.step2.subtitle }}</p>
                      <b-media no-body>
                        <b-media-aside class="mr-50">
                          <b-avatar
                            :src="timeline.step2.avatar"
                            size="38"
                          />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h6 class="mb-0">
                            {{ timeline.step2.avatarName }}
                          </h6>
                          <p class="mb-0">
                            {{ timeline.step2.occupation }}
                          </p>
                        </b-media-body>
                      </b-media>
                    </app-timeline-item>

                    <app-timeline-item variant="info">
                      <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                        <h6>{{ timeline.step3.title }}</h6>
                        <small class="timeline-item-time text-nowrap text-muted ml-1">{{ timeline.step3.duration }}</small>
                      </div>
                      <p>{{ timeline.step3.subtitle }}</p>
                      <b-avatar-group size="35px">
                        <b-avatar
                          v-for="avatar in timeline.step3.avatars"
                          :key="avatar.userImg"
                          v-b-tooltip.hover.top="'Tooltip!'"
                          :src="avatar.userImg"
                          class="pull-up"
                        />
                      </b-avatar-group>
                    </app-timeline-item>

                    <app-timeline-item
                      :title="timeline.step4.title"
                      :subtitle="timeline.step4.subtitle"
                      :time="timeline.step4.duration"
                      variant="danger"
                    /> -->
                  </app-timeline>
                  <!--/ timeline -->
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

        </transition-group>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import VueApexCharts from 'vue-apexcharts'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useJwt from '@/auth/useJwt'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import moment from 'moment/moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'
export default {
  components: {
    AppEchartLine,
    VueApexCharts,
    AppTimeline,
    AppTimelineItem,
    FeatherIcon,
  },
  mounted() {
    this.getSeries();
    this.getStatistics();
  },
  data() {
    return {
      isLoading: true,
      statistics: {},
      ordersLast30DaysData: {},
      appEchartLineKey: Date.now(),
      goalOverviewRadialBar: {
        data: {
          completed: '786,617',
          inProgress: '13,561',
          series: [83],
        },
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#28c76f'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: this.$t('Today Sales'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '23',
          subtitle: this.$t('Today Sales Counts'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: this.$t('Month Sales'),
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '234',
          subtitle: this.$t('Month Sales Count'),
          customClass: '',
        },
      ],
      timeline: {
        step1: {
          title: 'بدأ فهد عملية الشراء',
          img: require('@/assets/images/icons/json.png'),
          fileName: 'data.json',
          duration: 'مند 12 دقيقة',
        },
        step2: {
          title: 'أضاف زائر منتج إلى السلة',
          // subtitle: 'Project meeting with john @10:15am',
          avatar: 'https://cdn.mhd.sa/staging/mhd/24/40fa03d9240dad569bdd5c21a246a3ad511838.png',
          avatarName: 'آيفون برو 12',
          // occupation: 'CEO of Infibeam',
          duration: 'منذ 25 دقيقة',
        },
        step3: {
          title: 'طلب جديد بواسطة علي أحمد',
          subtitle: 'رقم الطلب #44352',
          duration: 'منذ 45 دقيقة',
          // avatars: [
          //   { userImg: require('@/assets/images/portrait/small/avatar-s-9.jpg'), name: 'Billy Hopkins' },
          //   { userImg: require('@/assets/images/portrait/small/avatar-s-6.jpg'), name: 'Amy Carson' },
          //   { userImg: require('@/assets/images/portrait/small/avatar-s-8.jpg'), name: 'Brandon Miles' },
          //   { userImg: require('@/assets/images/portrait/small/avatar-s-7.jpg'), name: 'Daisy Weber' },
          //   { userImg: require('@/assets/images/portrait/small/avatar-s-20.jpg'), name: 'Jenny Looper' },
          // ],
        },
        step4: {
          title: 'بدأ علي أحمد عملية الشراء',
          duration: 'منذ 1 ساعة',
          // subtitle: 'Add files to new design folder',
        },
      },
    }
  },
  methods: {
    getStatistics() {
      this.isLoading = true;

      if (this.$store.state.user.permissions.length == 1 && this.$store.state.user.permissions[0] == "show orders shipping zones") {
        window.location.href = '/shipping-zones/show-orders';
        return;
      }

      useJwt.get('/dashboard/get-statistics')
      .then(response => {
        // console.log(response.data.data)
        this.statistics = response.data.data
        this.isLoading = false;

        // subscription about to expire
        // if (response.data.data.subscriptionStatus == 'near expired') {
        //   this.$toast({
        //       component: ToastificationContent,
        //       props: {
        //         title: this.$t('Warning'),
        //         icon: 'InfoIcon',
        //         text: this.$t('The subscription is about to expire, renew your subscription'),
        //         variant: 'warning',
        //       },
        //     },
        //     {
        //       position: 'top-center',
        //       timeout: 1000000000000
        //     }
        //   );
        // }

        // subscription expired
        // if (response.data.data.subscriptionStatus == 'expired') {
        //   this.$toast({
        //       component: ToastificationContent,
        //       props: {
        //         title: this.$t('Warning'),
        //         icon: 'InfoIcon',
        //         text: this.$t('You do not have an active plan, renew your subscription'),
        //         variant: 'danger',
        //       },
        //     },
        //     {
        //       position: 'top-center',
        //       timeout: 1000000000000
        //     }
        //   );
        // }
      })
      .catch(err => {
        this.isLoading = false;
      })
    },
    getAxis() {
        let arrData = [];
        for(let i=0; i<30; i++) {
          arrData.push(moment().subtract(i,'d').format('DD/MM'))
        }
        
        return arrData.reverse();
    },
    getSeries() {
        useJwt.get('/dashboard/get-last-days-statistics')
        .then(response => {
          // console.log(response.data.data)
          this.ordersLast30DaysData.xAxisData = this.getAxis();
          this.ordersLast30DaysData.series = response.data.data.reverse();
          this.appEchartLineKey = Date.now(); // force re-renders of a component
        })
        .catch(err => {
          this.isLoading = false;
        })
    },
    updateStatistics() {
      this.getStatistics();
      this.getSeries();
    }
  }
}
</script>
